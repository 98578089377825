import React, { Fragment } from "react"
import SEO from "../components/seo"
import { Carousel } from "react-responsive-carousel"
import Img from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"

const IndexPage = () => {
  const {
    heroImage,
    flower,
    maya,
    jaka,
    rsvp,
    gallery,
  } = useStaticQuery(graphql`
    {
      heroImage: file(relativePath: { eq: "heroImage/heroImage.jpg" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      flower: file(relativePath: { eq: "decoration/flower.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      maya: file(relativePath: { eq: "profile/maya.png" }) {
        childImageSharp {
          fixed(width: 250) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      jaka: file(relativePath: { eq: "profile/jaka.png" }) {
        childImageSharp {
          fixed(width: 250) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      rsvp: file(
        relativePath: { eq: "rsvp/ivan-cabanas--E2nk5pNJ1g-unsplash.jpg" }
      ) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      gallery: allFile(filter: { relativeDirectory: { eq: "gallery" } }) {
        edges {
          node {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)
  return (
    <Fragment>
      <SEO title="You're invited!" />
      <div className="h-screen relative">
        <Img
          className="h-screen absolute inset-0"
          fluid={heroImage.childImageSharp.fluid}
        />
        <div className="absolute inset-0 text-white flex flex-col justify-center items-center">
          <p className="text-2xl font-thin">You are invited to wedding</p>
          <h1 className=" font-display text-6xl lg:text-10xl">Jaka & Maya</h1>
        </div>
      </div>
      <div className="bg-gold py-12">
        <div className="container mx-auto">
          <div class="flex mb-4 text-white flex-wrap">
            <div class="w-full md:w-1/2 text-center">
              <h2 className="font-display text-5xl">Holy Matronomy</h2>
              <p className="font-display text-2xl">
                02 November 2019 - 09.00 WIB
              </p>
              <p className="font-display text-2xl">Saint Monica Church BSD</p>
              <div className="my-4" />
              <a href="#">Add to Calendar | View Map</a>
            </div>
            <div class="w-full md:w-1/2 text-center">
              <h2 className="font-display text-5xl">Wedding Reception</h2>
              <p className="font-display text-2xl">
                02 November 2019 - 18.00 WIB
              </p>
              <p className="font-display text-2xl">JW Marriot Hotel</p>
              <div className="my-4" />
              <a href="#">Add to Calendar | View Map</a>
            </div>
          </div>
        </div>
      </div>
      <div className="container mx-auto py-24">
        <h2
          className="font-display text-5xl text-center my-8"
          style={{ color: "#CBAA5C" }}
        >
          The Happy Couple
        </h2>
        <div className="flex flex-wrap">
          <div className="w-full md:w-1/2 text-center px-8 mb-16 md:mb-0">
            <Img fixed={maya.childImageSharp.fixed} />
            <h2
              className="font-display text-5xl text-gold my-8"
              style={{ color: "#CBAA5C" }}
            >
              Maya Kurnia
            </h2>

            <p className="text-gray-600">
              It was in March 2013 I met her for the first time in Moreton
              Island. For me, Maya was too kind to be true. She is a caring and
              loving person. Thank God for giving me the chance to meet and know
              her. I will take care of her for the rest of my life. – Jaka
            </p>
          </div>
          <div className="w-full md:w-1/2 text-center px-8">
            <Img fixed={jaka.childImageSharp.fixed} />
            <h2
              className="font-display text-5xl text-gold my-8"
              style={{ color: "#CBAA5C" }}
            >
              Jaka Salim
            </h2>

            <p className="text-gray-600">
              Jaka is the most gentle man I’ve ever met. He is not the type of
              person who showers me with romantic surprises and words everyday,
              but he makes sure he will be there when I need him the most. I
              really can’t wait to spend the rest of my life with him. – Maya
            </p>
          </div>
        </div>
      </div>
      <div className="flex flex-wrap">
        <div className="w-full md:w-1/2">
          <Img fluid={rsvp.childImageSharp.fluid} className="h-full" />
        </div>
        <div className="w-full md:w-1/2 bg-gold">
          <form
            name="submission"
            method="POST"
            data-netlify="true"
            className="container mx-auto px-4 py-8"
          >
            <h2 className="font-display text-3xl text-center text-white ">
              RSVP
            </h2>
            <p className="text-center text-white mb-5">
              We’re so excited to celebrate with you.
              <br /> Please confirm before 24 October 2019
            </p>
            <div className="md:flex md:items-center mb-6 mx-auto">
              <div className="md:w-1/3">
                <label
                  className="block text-white font-bold font-display md:text-right mb-1 md:mb-0 pr-4"
                  for="inline-full-name"
                >
                  Your Name
                </label>
              </div>
              <div className="md:w-2/3">
                <input
                  className="bg-gold appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-white"
                  id="inline-full-name"
                  type="text"
                  name="Nama"
                />
              </div>
            </div>
            <div className="md:flex md:items-center mb-6">
              <div className="md:w-1/3">
                <label
                  className="block text-white font-bold font-display md:text-right mb-1 md:mb-0 pr-4"
                  for="email"
                >
                  Your Email
                </label>
              </div>
              <div className="md:w-2/3">
                <input
                  className="bg-gold appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-white"
                  id="email"
                  type="email"
                  name="Email"
                />
              </div>
            </div>

            <div className="text-center">
              <span className="text-white font-display text-3xl">
                Are you attending?
              </span>
              <div className="mt-2 text-white">
                <label className="inline-flex items-center">
                  <input
                    type="radio"
                    name="isAttend"
                    value="Yes"
                    className="form-radio"
                  />
                  <span className="ml-2">Yes</span>
                </label>
                <label className="inline-flex items-center ml-6">
                  <input
                    type="radio"
                    name="isAttend"
                    value="No"
                    className="form-radio"
                  />
                  <span className="ml-2">No</span>
                </label>
              </div>
            </div>
            <div className="text-center my-5">
              <button
                className="bg-transparent hover:bg-white text-white font-semibold hover:text-gold py-2 px-4 border border-white hover:border-transparent rounded"
                type="submit"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
      <div class="container mx-auto py-20">
        <h2 class="text-gold text-6xl text-center font-display mb-6">
          Gallery of #MayaJaka
        </h2>
        <div className="flex flex-wrap">
          {gallery.edges.map(image => {
            return (
              <div className="w-full md:w-1/3 ">
                <div
                  className="relative w-full"
                  style={{ paddingTop: "56.25%" }}
                >
                  <Img
                    className="w-full inset-0 h-full"
                    style={{ position: "absolute" }}
                    fluid={image.node.childImageSharp.fluid}
                  />
                </div>
              </div>
            )
          })}
        </div>
      </div>
      <div class="container mx-auto py-20 text-center">
        <h2 class="text-gold text-6xl  font-display mb-6">
          See you at #MayaJaka
        </h2>
        <p>
          Made with ❤️ by{" "}
          <a
            href="https://sawirstudio.com"
            target="_blank"
            rel="nofollow noreferrer"
          >
            Sawir Studio
          </a>
        </p>
      </div>
    </Fragment>
  )
}
export default IndexPage
